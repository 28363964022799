import { default as cryptoticket_45invalidwuvoWdn0tFMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/cryptoticket-invalid.vue?macro=true";
import { default as _91_slug_939fj3D9OyXXMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/geraete/[_slug].vue?macro=true";
import { default as index9tSAG1dhToMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/index.vue?macro=true";
import { default as maintenanceqyH5kicsxMMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/maintenance.vue?macro=true";
import { default as routes_45infoHoudYaJ0WTMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/routes-info.vue?macro=true";
import { default as bestaetigungWCeZrezhJUMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/bestaetigung.vue?macro=true";
import { default as fehlerPnZuiQHKnMMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/fehler.vue?macro=true";
import { default as persoenliche_45datenXkl7fxvcmrMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/kundenformular/persoenliche-daten.vue?macro=true";
import { default as kundenformulari4p7ifdg3IMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/kundenformular.vue?macro=true";
import { default as warenkorb_45virtuelles_45regalF4koT8bnUWMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/warenkorb-virtuelles-regal.vue?macro=true";
import { default as warenkorbP44Mci7UX4Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/warenkorb.vue?macro=true";
import { default as zusammenfassungZHRAHIWj3wMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/zusammenfassung.vue?macro=true";
import { default as _91_46_46_46config_93OlFRiTVyKlMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/suche/[...config].vue?macro=true";
import { default as transform_45paginationu41PUS11JJMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/suche/transform-pagination.ts?macro=true";
export default [
  {
    name: cryptoticket_45invalidwuvoWdn0tFMeta?.name ?? "cryptoticket-invalid",
    path: cryptoticket_45invalidwuvoWdn0tFMeta?.path ?? "/cryptoticket-invalid",
    meta: cryptoticket_45invalidwuvoWdn0tFMeta || {},
    alias: cryptoticket_45invalidwuvoWdn0tFMeta?.alias || [],
    redirect: cryptoticket_45invalidwuvoWdn0tFMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/cryptoticket-invalid.vue").then(m => m.default || m)
  },
  {
    name: _91_slug_939fj3D9OyXXMeta?.name ?? "geraete-_slug",
    path: _91_slug_939fj3D9OyXXMeta?.path ?? "/geraete/:_slug()",
    meta: _91_slug_939fj3D9OyXXMeta || {},
    alias: _91_slug_939fj3D9OyXXMeta?.alias || [],
    redirect: _91_slug_939fj3D9OyXXMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/geraete/[_slug].vue").then(m => m.default || m)
  },
  {
    name: index9tSAG1dhToMeta?.name ?? "index",
    path: index9tSAG1dhToMeta?.path ?? "/",
    meta: index9tSAG1dhToMeta || {},
    alias: index9tSAG1dhToMeta?.alias || [],
    redirect: index9tSAG1dhToMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceqyH5kicsxMMeta?.name ?? "maintenance",
    path: maintenanceqyH5kicsxMMeta?.path ?? "/maintenance",
    meta: maintenanceqyH5kicsxMMeta || {},
    alias: maintenanceqyH5kicsxMMeta?.alias || [],
    redirect: maintenanceqyH5kicsxMMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: routes_45infoHoudYaJ0WTMeta?.name ?? "routes-info",
    path: routes_45infoHoudYaJ0WTMeta?.path ?? "/routes-info",
    meta: routes_45infoHoudYaJ0WTMeta || {},
    alias: routes_45infoHoudYaJ0WTMeta?.alias || [],
    redirect: routes_45infoHoudYaJ0WTMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/routes-info.vue").then(m => m.default || m)
  },
  {
    name: bestaetigungWCeZrezhJUMeta?.name ?? "shop-bestaetigung",
    path: bestaetigungWCeZrezhJUMeta?.path ?? "/shop/bestaetigung",
    meta: bestaetigungWCeZrezhJUMeta || {},
    alias: bestaetigungWCeZrezhJUMeta?.alias || [],
    redirect: bestaetigungWCeZrezhJUMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: fehlerPnZuiQHKnMMeta?.name ?? "shop-fehler",
    path: fehlerPnZuiQHKnMMeta?.path ?? "/shop/fehler",
    meta: fehlerPnZuiQHKnMMeta || {},
    alias: fehlerPnZuiQHKnMMeta?.alias || [],
    redirect: fehlerPnZuiQHKnMMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/fehler.vue").then(m => m.default || m)
  },
  {
    name: kundenformulari4p7ifdg3IMeta?.name ?? "shop-kundenformular",
    path: kundenformulari4p7ifdg3IMeta?.path ?? "/shop/kundenformular",
    meta: kundenformulari4p7ifdg3IMeta || {},
    alias: kundenformulari4p7ifdg3IMeta?.alias || [],
    redirect: kundenformulari4p7ifdg3IMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/kundenformular.vue").then(m => m.default || m),
    children: [
  {
    name: persoenliche_45datenXkl7fxvcmrMeta?.name ?? "shop-kundenformular-persoenliche-daten",
    path: persoenliche_45datenXkl7fxvcmrMeta?.path ?? "persoenliche-daten",
    meta: persoenliche_45datenXkl7fxvcmrMeta || {},
    alias: persoenliche_45datenXkl7fxvcmrMeta?.alias || [],
    redirect: persoenliche_45datenXkl7fxvcmrMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/kundenformular/persoenliche-daten.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warenkorb_45virtuelles_45regalF4koT8bnUWMeta?.name ?? "shop-warenkorb-virtuelles-regal",
    path: warenkorb_45virtuelles_45regalF4koT8bnUWMeta?.path ?? "/shop/warenkorb-virtuelles-regal",
    meta: warenkorb_45virtuelles_45regalF4koT8bnUWMeta || {},
    alias: warenkorb_45virtuelles_45regalF4koT8bnUWMeta?.alias || [],
    redirect: warenkorb_45virtuelles_45regalF4koT8bnUWMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/warenkorb-virtuelles-regal.vue").then(m => m.default || m)
  },
  {
    name: warenkorbP44Mci7UX4Meta?.name ?? "shop-warenkorb",
    path: warenkorbP44Mci7UX4Meta?.path ?? "/shop/warenkorb",
    meta: warenkorbP44Mci7UX4Meta || {},
    alias: warenkorbP44Mci7UX4Meta?.alias || [],
    redirect: warenkorbP44Mci7UX4Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/warenkorb.vue").then(m => m.default || m)
  },
  {
    name: zusammenfassungZHRAHIWj3wMeta?.name ?? "shop-zusammenfassung",
    path: zusammenfassungZHRAHIWj3wMeta?.path ?? "/shop/zusammenfassung",
    meta: zusammenfassungZHRAHIWj3wMeta || {},
    alias: zusammenfassungZHRAHIWj3wMeta?.alias || [],
    redirect: zusammenfassungZHRAHIWj3wMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/shop/zusammenfassung.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46config_93OlFRiTVyKlMeta?.name ?? "suche-config",
    path: _91_46_46_46config_93OlFRiTVyKlMeta?.path ?? "/suche/:config(.*)*",
    meta: _91_46_46_46config_93OlFRiTVyKlMeta || {},
    alias: _91_46_46_46config_93OlFRiTVyKlMeta?.alias || [],
    redirect: _91_46_46_46config_93OlFRiTVyKlMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/suche/[...config].vue").then(m => m.default || m)
  },
  {
    name: transform_45paginationu41PUS11JJMeta?.name ?? "suche-transform-pagination",
    path: transform_45paginationu41PUS11JJMeta?.path ?? "/suche/transform-pagination",
    meta: transform_45paginationu41PUS11JJMeta || {},
    alias: transform_45paginationu41PUS11JJMeta?.alias || [],
    redirect: transform_45paginationu41PUS11JJMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20241104070604/pages/suche/transform-pagination.ts").then(m => m.default || m)
  }
]